import { render, staticRenderFns } from "./review_detail.vue?vue&type=template&id=26a78630&scoped=true&"
import script from "./review_detail.vue?vue&type=script&lang=js&"
export * from "./review_detail.vue?vue&type=script&lang=js&"
import style0 from "./review_detail.vue?vue&type=style&index=0&id=26a78630&prod&scoped=scoped&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "26a78630",
  null
  
)

export default component.exports