<template>
	<div class="store-container"  >
		<el-row>
			<el-col :span='1'>
				<div class='store-title'>
					<button class="edit-btn" @click="edit">
						<span><img src="../assets/img/no-edit.png"></span>
						<span>返回</span>
					</button>
				</div>
			</el-col>
			<el-col :span='20' :offset='3'>
				<div class="store-from" v-loading="loading">
					<el-form class="store-from-content" ref="form" :rules="rules" :model="form" label-width="120px">
						<div class="store-from-title">课程详情：</div>
						<el-form-item label="课程封面:">
							<img :src="alOssUrl + competition_course.cover" style="width: 100px;height: 100px;" alt=""
								@click="showFile(competition_course.cover)">
						</el-form-item>
						<el-form-item label="课程标题:">
							{{competition_course.name}}
						</el-form-item>
						<el-form-item label="课程主题:">
							{{competition_course.relate_course_category_name}}
						</el-form-item>
						<el-form-item label="学时:">
							{{competition_course.hour}}
						</el-form-item>
						<el-form-item label="适用学段:">
							{{util.setStage(competition_course.apply_to_stages)}}
						</el-form-item>
						<el-form-item label="参赛地区:">
							{{competition_course.relate_region_name}}
						</el-form-item>
						<el-form-item label="课程简介:">
							{{competition_course.opinion_sharing}}
						</el-form-item>
						<el-form-item label="作者自荐:" prop="introduction">
							<div class="introduction" style="width: 610px;">
								<el-row>
									<el-col :span='24'>
										<span class="signature"
											v-for="item in competition_course.relate_competition_course_for_author_recommendation_signature">
											{{item.relate_competition_course_author_recommendation_signature.name}}
										</span>
									</el-col>
								</el-row>
								<el-row style='margin-top: 10px;'>
									<el-col :span='24'>
										<div ref='author_recommendation'
											v-html="competition_course.author_recommendation"
											class="author-recommendation-content">
										</div>
										<!-- <div class="author-recommendation-show">
											展开
										</div> -->
									</el-col>
								</el-row>
							</div>
						</el-form-item>
						<el-form-item label="课程附件:">
							<div class="my-upload-list">
								<div v-for="(item,index) in competition_course_file">
									<div class="upload-flex">
										<img :src="util.setFileIcon(item.path)" class='my-file'>
										<span>{{ item.name.length>25 ? item.name.substr(0, 25)+'...':item.name }}({{item.size}})</span>
									</div>
									<div>
										<span @click='showFile(item.path)' class="show-flie-open">预览</span>
									</div>
								</div>
							</div>
						</el-form-item>
						<div class="store-from-title">评分：</div>
						<el-form-item label="总体:" prop="rate">
							<el-rate v-model="form.rate" show-text :allow-half='true' :colors='colors'>
							</el-rate>
						</el-form-item>
						<el-form-item label="标签选择:" prop="label">
							<div class="comment-content">
								<div v-for="(item,index) in CommentList"
									:class="CommentCheck.includes(item.id) ? 'comment-check-active':'comment-check'"
									@click="selectChecked(item.id)">{{item.name}}</div>
							</div>
						</el-form-item>
						<el-form-item label="评论内容:" prop="content" style='margin-top: 10px;'>
							<el-input type="textarea" placeholder="请输入评论内容" v-model="form.content" rows='10'
								maxlength="300" show-word-limit style="width: 610px;">
							</el-input>
						</el-form-item>
					</el-form>
				</div>
			</el-col>
		</el-row>
		<div style="text-align: center">
			<el-button type="primary" class='btn-primary' @click="submit('form')">确定</el-button>
		</div>
		<showFile ref='show'></showFile>
	</div>
</template>

<script>
	import {
		client
	} from '../util/ali_oss.js'
	import editor from '../components/editor.vue'
	import showFile from '../components/showFile.vue'
	export default {
		name: 'index',
		components: {
			showFile
		},
		data() {
			return {
				loading: false,
				rules: {
					rate: [{
						required: true,
						message: '请选择评分',
						trigger: 'blur'
					}],
					label: [{
						required: true,
						message: '请选择标签',
						trigger: 'change'
					}],
					content: [{
						required: true,
						message: '请输入评论内容',
						trigger: 'change'
					}],
				},
				form: {
					rate: null,
					label: '',
					content: '',
				},
				colors: ['#99A9BF', '#F7BA2A', '#FF9900'],
				CommentList: [],
				CommentCheck: [],
				// 详情信息
				competition_course: '',
				competition_course_file: [],
			}
		},
		methods: {
			edit() {
				this.$router.go(-1)
			},
			showFile(url) {
				this.$refs.show.preview(url)
			},
			submit() {
				let {
					rate,
					label,
					content
				} = this.form
				if (rate == '') {
					this.$message.error('请评分！');
					return
				}
				if (this.CommentCheck.length == 0) {
					this.$message.error('请选择标签！');
					return
				}
				if (content == '') {
					this.$message.error('请输入评论内容！');
					return
				}
				let obj = {
					rate,
					content,
					competition_course_comment_signature_ids: this.CommentCheck
				}
				if (this.$route.query.course_id && this.$route.query.comment_id) {
					let id = '?id=' + this.$route.query.comment_id + '&competition_course_id=' + this.$route.query
						.course_id
					this.http.commentUpdate(id, obj).then((res) => {
						if (res.data.code == 200) {
							this.$message({
								message: '修改成功！',
								type: 'success'
							});
							this.$router.push({
								name: 'review'
							})
						} else {
							this.$message.error(res.data.msg);
						}
					})
				} else {
					let id = '?competition_course_id=' + this.$route.query.course_id
					this.http.commentSure(id, obj).then((res) => {
						if (res.data.code == 200) {
							this.$message({
								message: '评分成功！',
								type: 'success'
							});
							this.$router.push({
								name: 'review'
							})
						} else {
							this.$message.error(res.data.msg);
						}
					})
				}
			},
			// 获取评论标签列表
			getCommentList() {
				this.http.getCommentList().then((res) => {
					this.CommentList = res.data.data.list
				})
			},
			selectChecked(i) {
				if (this.CommentCheck.includes(i)) {
					this.CommentCheck = this.CommentCheck.filter(function(ele) {
						return ele != i;
					});
				} else {
					this.CommentCheck.push(i);
				}
			},
			filterArray(arr) {
				var arr = arr
				for (var i = 0; i < arr.length; i++) {
					if (arr[i] == '' || arr[i] == null || typeof(arr[i]) == undefined) {
						arr.splice(i, 1);
						i = i - 1;
					}
				}
				return arr
			},
			//基本资料
			getDetail() {
				let id = '?id=' + this.$route.query.course_id
				this.loading=true;
				this.http.getExpertDetail(id).then((res) => {
					this.loading=false;
					let {
						competition_course,
						competition_course_audio,
						competition_course_video,
						competition_course_author,
						competition_course_file
					} = res.data.data
					var relate_signature = competition_course
						.relate_competition_course_for_author_recommendation_signature.map((item) => {
							if (item.relate_competition_course_author_recommendation_signature != null) {
								return item
							}
						})
					relate_signature=this.filterArray(relate_signature)	
					this.competition_course = {
						cover: competition_course.cover,
						name: competition_course.name,
						hour: competition_course.hour,
						relate_course_category_name: competition_course.relate_course_category.name,
						group: competition_course.group,
						relate_region_name: competition_course.relate_region.name,
						opinion_sharing: competition_course.opinion_sharing,
						relate_competition_course_for_author_recommendation_signature: relate_signature,
						author_recommendation: competition_course.author_recommendation,
					}
					this.competition_course_file = competition_course_file
				})
			},
			//评分详情
			getCommentDetail() {
				let id = '?id=' + this.$route.query.comment_id
				this.http.getCommentDetail(id).then((res) => {
					this.form.rate = parseFloat(res.data.data.competition_course_comment.rate)
					this.form.content = res.data.data.competition_course_comment.content
					this.CommentCheck = res.data.data.competition_course_comment
						.relate_competition_course_comment_for_comment_signature.map((item) => {
							if (item.relate_competition_course_comment_signature != null) {
								return item.relate_competition_course_comment_signature.id
							}
						})
					this.CommentCheck=this.filterArray(this.CommentCheck)	
					console.log(this.CommentCheck)
				})
			}
		},
		mounted() {
			// this.getCommentList()
			if (this.$route.query.comment_id) {
				this.getCommentDetail()
			}
			this.getDetail()
			sessionStorage.setItem("review", true)

			// const author_recommendation = this.$refs.author_recommendation.offsetHeight
		},
		watch: {

		}
	}
</script>

<style scoped="scoped">
	.store-container {
		width: 65vw;
		min-width: 1000px;
		background-color: white;
		margin: 0 auto;
		padding: 1vw 1vw;
		box-shadow: 0 0 100px rgba(31, 180, 249, 0.26);
		box-sizing: border-box;
		margin-bottom: 30px;
	}

	.store-container img {
		cursor: pointer;
	}

	.store-from-title {
		font-weight: bold;
		font-size: 20px;
		margin-top: 10px;
		margin-bottom: 20px;
		margin-left: 20px;
	}

	.store-from {
		padding-bottom: 10px;
		overflow-y: scroll;
		font-size: 14px;
		color: #333333;
	}

	.store-from-content {
		width: 100%;
		padding-bottom: 15px;
	}

	.store-from .el-form-item {
		margin-bottom: 5px;
	}

	.textarea-content {
		width: 610px;
		border: 1px solid #dcdfe6;
		border-radius: 4px;
		height: 200px;
		overflow-y: scroll;
		padding: 0 5px;
	}

	/* 评分 */
	.el-rate {
		margin-top: 10px;
	}


	.comment-content {
		width: 610px;
	}

	/* 评论标签选中 */
	.comment-check-active {
		padding: 0 26px;
		height: 37px;
		line-height: 37px;
		background: #D9F1FF;
		border-radius: 100px;
		color: #1FB4F9;
		text-align: center;
		font-weight: 400;
		cursor: pointer;
		float: left;
		margin-right: 10px;
		margin-top: 5px;
	}

	.comment-check {
		padding: 0 25px;
		height: 35px;
		line-height: 35px;
		background: #FFFFFF;
		border: 1px solid #E5E5E5;
		border-radius: 90px;
		text-align: center;
		color: #666666;
		font-weight: 400;
		cursor: pointer;
		float: left;
		margin-right: 10px;
		margin-top: 5px;
	}


	.signature {
		padding: 0 15px;
		border-radius: 100px;
		text-align: center;
		height: 30px;
		line-height: 30px;
		font-weight: 400;
		display: inline-block;
		margin-right: 10px;
		border: 1px solid #dcdfe6;
	}

	.author-recommendation-content {
		border: 1px solid #dcdfe6;
		border-radius: 6px;
		padding: 0 10px;
		box-sizing: border-box;
		height: 200px;
		overflow-y: scroll;
		margin-bottom: 10px;
	}

	/* 展开收缩 */
	.author-recommendation-show {
		width: 100%;
		border: 1px solid red;
		text-align: center;
		cursor: pointer;
		/* background: linear-gradient(to top,black,white); */
	}
</style>
